<template>
  <b-row>
    <b-col
      cols="12"
      md="5"
    >
      <b-card title="Müşteri Arama">
        <b-form-group>
          <b-form-input
            v-model="keyword"
            placeholder="Firma Adı, Telefon"
            @keydown.enter="getCustomers"
          />
        </b-form-group>
        <div class="text-center">
          <b-button
            variant="success"
            @click="getCustomers"
          >
            <FeatherIcon icon="SearchIcon" />
            Müşteri Ara
          </b-button>
        </div>
      </b-card>
    </b-col>
    <b-col
      cols="12"
      md="7"
    >
      <b-card no-body>
        <b-card-header>
          <b-card-title>Müşteriler</b-card-title>
        </b-card-header>
        <b-table
          v-if="customers.length > 0"
          responsive="sm"
          :fields="fields"
          :items="customers"
          striped
        >
          <template #cell(company)="data">
            {{ data.item.company }}
            <div class="text-muted font-small-2">
              {{ data.item.country }}
              {{ data.item.city }}
            </div>
          </template>
          <template #cell(control)="data">
            <b-button
              size="sm"
              variant="success"
              :to="'/app/customers/accounts/add/' + data.item.id"
              class="text-nowrap"
            >
              <FeatherIcon icon="PlusIcon" />
              Oluştur
            </b-button>
          </template>
        </b-table>
        <b-card-footer v-else>
          <b-alert show>
            <div class="alert-body text-center">
              <div class="mb-1">
                Aradığınız müşteri bulunamadı.!
              </div>
              <b-button
                variant="info"
                size="sm"
                to="/app/customers/add"
              >
                Müşteri Kartı Oluştur
              </b-button>
            </div>
          </b-alert>
        </b-card-footer>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow, BCol, BCard, BButton, BTable, BFormGroup, BFormInput, BAlert, BCardHeader, BCardTitle, BCardFooter,
} from 'bootstrap-vue'

export default {
  name: 'CustomerSearch',
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BTable,
    BFormGroup,
    BFormInput,
    BAlert,
    BCardHeader,
    BCardTitle,
    BCardFooter,
  },
  data() {
    return {
      keyword: '',
      fields: [
        {
          key: 'company',
          label: 'FİRMA ADI',
        },
        {
          key: 'control',
          label: '',
          tdClass: 'text-right',
        },
      ],
    }
  },
  computed: {
    customers() {
      return this.$store.getters['customers/dataList']
    },
  },
  created() {
    this.getCustomers()
  },
  methods: {
    getCustomers() {
      const params = {
        select: [
          'customers.id AS id',
          'customers.company AS company',
          'countries.title AS country',
          'cities.title AS city',
        ],
        start: 0,
        limit: 10,
      }
      if (this.keyword) {
        params.or_like = {
          'customers.company': this.keyword,
          'customers.phone': this.keyword,
        }
      }
      this.$store.dispatch('customers/getDataList', params)
    },
  },
}
</script>
